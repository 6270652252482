const $win = $(window);
const $doc = $(document);
function resizePlayer(iframes, ratio) {
	if (!iframes[0]) return;
	var win = jQuery(".intro"),
		width = win.width(),
		playerWidth,
		height = win.height(),
		playerHeight,
		ratio = ratio || 16 / 9;

	//jQuery(iframes).each(function() {

	var current = jQuery(iframes[0]);
	if (width / ratio < height) {
		playerWidth = Math.ceil(height * ratio);
		current
			.width(playerWidth)
			.height(height)
			.css({
				left: (width - playerWidth) / 2,
				top: 0
			});
	} else {
		playerHeight = Math.ceil(width / ratio);
		current
			.width(width)
			.height(playerHeight)
			.css({
				left: 0,
				top: (height - playerHeight) / 2
			});
	}
	// });

}


$doc.ready(function(){


	// Scroll To Section
	$('[data-scroll-nav] a').on('click', function(e){
		$('[data-scroll-nav]').find('.active').removeClass('active');
		const targetSection = $($(this).attr('href'));


		if (targetSection.length) {
			e.preventDefault();
			$(this).parent().addClass('active');
			$('html, body').animate({
				scrollTop: targetSection.offset().top - 70
			}, 1000)
		}
	});

	var iframes = [".slide-media"];
	resizePlayer(iframes, 16 / 9);


	jQuery(window).resize(function () {
		resizePlayer(iframes, 16 / 9);
	});

	$('.nav ul a').each(function(){
		var $this = $(this);
		if ( $this.siblings('ul').length ) {

			$this.parent().addClass('hasDropdown')
			$this.parent().append('<span class="hasDropdown__arrow"></span>')
		}
	})

	setEqualHight();
	function setEqualHight(){
		var postH6maxHeight = 0;
		$('.posts .col post').each(function(){
		
			if ( postH6maxHeight < $(this).outerHeight()) {
				postH6maxHeight = $(this).outerHeight();
			}

		});

		//$('.posts .col .post').css("height", postH6maxHeight);
	}


	$('.nav-trigger-holder').on('click', function(event) {
		$(this).children().toggleClass('nav-trigger--active');
  		$('.header').toggleClass('expanded');
  		event.preventDefault();

	});

	$('.hasDropdown__arrow').on('click', function(e){
		e.preventDefault()
		if ($win.width() < 1024) {
			$(this).parent().addClass("expanded");
			$(this).parent().siblings().addClass("not-expanded");
		}
	})


	$doc.on("click",".hasDropdown__title",function(e) {
		e.preventDefault()
		if ($win.width() < 1024) {
			$(this).parent().parent().removeClass("expanded");
			$(this).parent().parent().siblings().removeClass("not-expanded");
		}
	});

	mobileMenuTitle ();
	function mobileMenuTitle (){
		$('.hasDropdown').each(function(){
			var $this =  $(this);
			var thisTxt = $this.children().siblings('a').text();
			var appendStr = '<li class="hasDropdown__title"><a href="#">' + thisTxt + '</a></li>';

			$this.children().siblings('ul').prepend(appendStr);
		})
	}
/*
	$('.search').on('click', function(e){
		e.preventDefault();
		
	})
*/
	$(".js-search-trigger").on("click", function(event) {
		event.preventDefault();

		$(this).toggleClass("active");

		$(".search").toggleClass("visible");
	});

	$('.slider-testimonial').slick({
			slidesToShow:1,
			prevArrow: '<div class="prev"><a type="button" class="slick-prev link"><svg xmlns="http://www.w3.org/2000/svg" width="38.714" height="8.885" viewBox="0 0 38.714 8.885">\n' +
				'\t\t\t\t\t\t\t\t\t\t<defs>\n' +
				'\t\t\t\t\t\t\t\t\t\t\t<style>.a{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:1.25px;}</style>\n' +
				'\t\t\t\t\t\t\t\t\t\t</defs>\n' +
				'\t\t\t\t\t\t\t\t\t\t<g transform="translate(-353 -55)">\n' +
				'\t\t\t\t\t\t\t\t\t\t\t<path class="a" d="M0,0,4,4,0,8" transform="translate(386.83 55.442)"/><path class="a" d="M0,.5H37.246" transform="translate(353 58.942)"/>\n' +
				'\t\t\t\t\t\t\t\t\t\t</g>\n' +
				'\t\t\t\t\t\t\t\t\t</svg></a></div>',
			nextArrow: '<div class="next"><a type="button" class="slick-next link"><svg xmlns="http://www.w3.org/2000/svg" width="38.714" height="8.885" viewBox="0 0 38.714 8.885">\n' +
				'\t\t\t\t\t\t\t\t\t\t<defs>\n' +
				'\t\t\t\t\t\t\t\t\t\t\t<style>.a{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:1.25px;}</style>\n' +
				'\t\t\t\t\t\t\t\t\t\t</defs>\n' +
				'\t\t\t\t\t\t\t\t\t\t<g transform="translate(-353 -55)">\n' +
				'\t\t\t\t\t\t\t\t\t\t\t<path class="a" d="M0,0,4,4,0,8" transform="translate(386.83 55.442)"/><path class="a" d="M0,.5H37.246" transform="translate(353 58.942)"/>\n' +
				'\t\t\t\t\t\t\t\t\t\t</g>\n' +
				'\t\t\t\t\t\t\t\t\t</svg></a></div>'

	});
});

// Your code goes here...
// jQuery.ready is no longer needed

jQuery(document).ready(function(){

	var videoCan = jQuery('.video-holder'),
		videoID = videoCan.data('video');
	go = false;

	if (videoID && jQuery(window).width() <= 768) {
		videoCan.find('iframe').remove();
	}

	var windowWidth = jQuery(window).width();
	var windowHeight = jQuery(window).height();

	if(windowWidth > 1400){
		addedHeight = 90;
	}else{
		addedHeight = 58;
	}

	if(windowWidth > 768) {

		jQuery('.menu .sub-menu').each(function () {
			var menuHeight = jQuery(this).outerHeight() + addedHeight;
			if (menuHeight > windowHeight) {
				jQuery(this).addClass('break');
			} else {
				if(jQuery(this).hasClass('break')) {
					jQuery(this).removeClass('break');
				}
			}
		});

	}

	jQuery(window).resize(function(){
		var windowWidth = jQuery(window).width(),
			windowHeight = jQuery(window).height();

		if(windowWidth > 1400){
			addedHeight = 90;
		}else{
			addedHeight = 58;
		}

		if(windowWidth > 768) {

			jQuery('.menu .sub-menu').each(function () {
				var menuHeight = jQuery(this).outerHeight() + addedHeight;
				if (menuHeight > windowHeight) {
					jQuery(this).addClass('break');
				} else {
					if(jQuery(this).hasClass('break')) {
						jQuery(this).removeClass('break');
					}
				}
			});

			if (go) {
				videoCan.html('<iframe class="embed-player slide-media" src="https://player.vimeo.com/video/' + videoID + '?quality=540p&amp;api=1&amp;byline=0&amp;controls=0&amp;portrait=0&amp;title=0&amp;background=1&amp;mute=1&amp;loop=1&amp;autoplay=1" width="100%" frameborder="0" allow="autoplay" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="" ></iframe>');
				resizePlayer([".slide-media"], 16 / 9);
				go = false;
			}

		} else {
			if (!go && videoCan.find('iframe').length) {
				videoCan.find('iframe').remove();
				go = true;
			}
		}
	});

	jQuery('.posts .col .post').matchHeight({
		property: 'min-height'
	});

});